import React from "react"
import { Link as BaseLink, graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../../components/layout"
import BlogContainer from "../../components/BlogContainer"

function BlogIndex({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout>
      <BlogContainer>
        {posts.length === 0 && <ComingSoon>Coming Soon</ComingSoon>}
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <div key={post.id}>
                <h1>
                  <Link to={`/blog${post.frontmatter.path}`}>
                    {post.frontmatter.title}
                  </Link>
                </h1>
                <h3>{post.frontmatter.date}</h3>
                <p>{post.excerpt}</p>
              </div>
            )
          })}
      </BlogContainer>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { publish: { eq: true } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "D MMMM YYYY")
            path
          }
        }
      }
    }
  }
`

const ComingSoon = styled('h1')`
  text-align: center;
  font-family: Playfair Display;
`

const Link = styled(BaseLink)`
  color: ${({ theme }) => theme.contrast};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`
