import { css } from "styled-components"

import { devices } from "./media"

export default css`
  color: ${({ theme }) => theme.contrast};
  h1,
  h2,
  h3 {
    font-family: "Playfair Display", sans-serif;
  }
  p {
    text-align: justify;
  }
  blockquote {
    font-style: italic;
    background-color: ${({ theme }) => theme.secondary3};
    padding: 20px;
    border-left: ${({ theme }) => `solid 3px ${theme.contrast}`};
    border-right: ${({ theme }) => `solid 3px ${theme.contrast}`};
    @media ${devices.mobileL} {
      margin-left: 0px;
      margin-right: 0px;
    }
    a {
      color: ${({ theme }) => theme.contrast};
    }
  }
  code[class*="language-"] {
    margin-right: 4px;
    padding-left: 5px;
  }
`
