import styled from "styled-components"
import blogStyles from "../styles/blog"
import { devices } from "../styles/media"

const BlogContainer = styled("div")`
  max-width: 980px;
  margin: auto;
  margin-top: 2rem;
  padding: 20px;
  ${blogStyles};
  @media ${devices.tablet} {
    margin-top: 0px;
  }
`

export default BlogContainer
